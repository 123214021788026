function initDropdown() {
  const dropdown = Array.from(document.querySelectorAll('.js-dropdown'))
  if (!dropdown.length) return
  const CLASS_DROPDOWN = 'js-dropdown'
  const CLASS_DROPDOWN_TOP = 'js-dropdown-top'
  const CLASS_DROPDOWN_SUB = 'js-dropdown-sub'
  function onDocumentClick(e) {
    const dropDown = e.target.closest(`.${CLASS_DROPDOWN}`)
    if (dropDown) return
    const dropdownSub = Array.from(document.querySelectorAll(`.${CLASS_DROPDOWN_SUB}`))
    dropdownSub.forEach((item) => {
      item.style.display = 'none'
    })
    document.removeEventListener('click', onDocumentClick)
  }
  function onDropdownClick(e) {
    if (!(e.target.closest(`.${CLASS_DROPDOWN_TOP}`))) return
    const dropDown = e.target.closest(`.${CLASS_DROPDOWN}`)
    const dropDownSub = dropDown.querySelector(`.${CLASS_DROPDOWN_SUB}`)
    if (dropDownSub.style.display === 'block') {
      dropDownSub.style.display = 'none'
      document.removeEventListener('click', onDocumentClick)
    } else {
      dropDownSub.style.display = 'block'
      document.addEventListener('click', onDocumentClick)
    }
  }

  dropdown.forEach(item => item.addEventListener('click', onDropdownClick))
}

export default initDropdown
