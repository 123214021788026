function initChat() {
  const btnOpenChat = document.querySelector('.js-btn-open-chat')
  if (!btnOpenChat) return
  const chat = document.querySelector('.js-chat')
  const lastMessage = document.querySelector('.js-last-message')

  function onBtnOpenChatClick() {
    chat.style.display = 'block'
    lastMessage.style.display = 'none'
  }

  btnOpenChat.addEventListener('click', onBtnOpenChatClick)
}

function initTextarea() {
  function pasteIntoInput(el, text) {
    el.focus()
    if (typeof el.selectionStart === 'number'
      && typeof el.selectionEnd === 'number') {
      const val = el.value
      const selStart = el.selectionStart
      // eslint-disable-next-line no-param-reassign
      el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
      // eslint-disable-next-line no-param-reassign,no-multi-assign
      el.selectionEnd = el.selectionStart = selStart + text.length
    } else if (typeof document.selection !== 'undefined') {
      const textRange = document.selection.createRange()
      textRange.text = text
      textRange.collapse(false)
      textRange.select()
    }
  }
  const $textareaEditable = $('.js-textarea-editable')

  function changeElHeight(el) {
    // eslint-disable-next-line no-param-reassign
    el.style.height = '5px'
    // eslint-disable-next-line no-param-reassign
    el.style.height = `${el.scrollHeight + 2}px`
  }

  $textareaEditable.on('input', function () {
    changeElHeight(this)
  })
  $textareaEditable.keyup(function (event) {
    if (event.keyCode === 13 && event.ctrlKey) {
      pasteIntoInput(this, '\n')
      changeElHeight(this)
    }
  })
  $textareaEditable.on('sendChatMessage', function () {
    console.log('sendChatMessage')
    changeElHeight(this)
  })
}

export { initChat, initTextarea }
