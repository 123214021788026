function initHeaderScroll() {
  const mainHeader = document.querySelector('.main-header')
  const CLASS_INNER_FIXED = 'main-header__inner_fixed'
  if (!mainHeader) return
  const mainHeaderInner = document.querySelector('.main-header__inner')
  const { height } = mainHeader.getBoundingClientRect()
  function onWindowScroll() {
    if (mainHeader.getBoundingClientRect().top < 0) {
      mainHeader.style.minHeight = `${height}px`
      mainHeaderInner.classList.add(CLASS_INNER_FIXED)
    } else {
      mainHeader.style.minHeight = 'auto'
      mainHeaderInner.classList.remove(CLASS_INNER_FIXED)
    }
  }

  window.addEventListener('scroll', onWindowScroll)
}

export default initHeaderScroll
