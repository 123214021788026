function initLoader() {
  const LOADER_DELAY = 500
  const CLASS_LOADER_ACTIVE = 'loader_active'
  const formWithLoader = Array.from(document.querySelectorAll('form'))
  const $form = $('form')
  const loader = document.querySelector('.loader')
  let isLoading = true
  if (!formWithLoader.length || !loader) return

  function showLoader() {
    if (!isLoading) return
    isLoading = true
    loader.classList.add(CLASS_LOADER_ACTIVE)
  }
  function hideLoader() {
    isLoading = false
    loader.classList.remove(CLASS_LOADER_ACTIVE)
  }

  function onFormWithLoaderSubmit() {
    setTimeout(() => {
      showLoader()
    }, LOADER_DELAY)
  }

  $form.on('ajax:complete', () => {
    hideLoader()
  })

  formWithLoader.forEach((formItem) => {
    formItem.addEventListener('submit', onFormWithLoaderSubmit)
  })
}

export default initLoader
