import toastr from 'toastr'

class FlashNotice {
  constructor() {
    toastr.clear()
    toastr.options = {
      closeButton: true,
      debug: false,
      progressBar: false,
      preventDuplicates: true,
      positionClass: 'toast-top-center',
      onclick: null,
      showDuration: '400',
      hideDuration: '400',
      timeOut: 3000,
      extendedTimeOut: 3000,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      closeDuration: '300',
      closeHtml: '<button><i class=\'icon icon-remove icon_small\'></i></button>',
    }


    $('.flash_error, .flash_warning, .flash_notice, .flash_alert, .flash_success, .flash_info, .flash_recaptcha_error, .form__server-response-txt').each(function () {
      // toastr.clear();
      // toastr.remove();
      const $flash = $(this)
      let type = $flash.data('type')
      let title = null
      if (!type) {
        return
      }
      const message = $flash.html()
      if (type === 'notice') {
        type = 'success'
      }
      if (type === 'alert') {
        type = 'error'
      }
      if (type === 'recaptcha_error') {
        type = 'error'
      }

      // info, success, warning or error
      if (type === 'info') {
        type = 'info'
        title = 'Info'
      }
      if (type === 'success') {
        type = 'success'
        title = 'Спасибо!'
      }
      if (type === 'warning') {
        type = 'warning'
        title = 'Attention!'
      }
      if (type === 'error') {
        type = 'error'
        title = 'D’oh!'
      }
      const getTitle = title ? `<h3 class="toast__title">${title}</h3>` : ''
      toastr[type](`
         ${getTitle}
         <div class="toast__message">${message}</div>`)

      $(this).remove()
    })
  }
}

export default FlashNotice
