
function initNotification() {
   const notificationContent = document.querySelector('.js-notification-block-content')
   if (!notificationContent) return

  function onDocumentClick(e) {
     const $toolbar = $(e.target).closest('.toolbar')
     if (!$toolbar.length) {
       $('.toolbar__menu').addClass('hidden')
       document.removeEventListener('click', onDocumentClick)
     }
  }

   function onNotificationClick(e) {
     function initToolbar() {
       const $toolbar = $(e.target).closest('.toolbar')
       if (!$toolbar.length) return

       const $toolbarMenu = $toolbar.find('.toolbar__menu')
       if (!$toolbarMenu.hasClass('hidden')) {
         $toolbarMenu.addClass('hidden')
         document.removeEventListener('click', onDocumentClick)
       } else {
         $('.toolbar__menu').addClass('hidden')
         $toolbarMenu.removeClass('hidden')
         setTimeout(() => {
           document.addEventListener('click', onDocumentClick)
         }, 10)
       }
     }

     function initNotificationLink() {
       const $notification = $(e.target).closest('.notification-item')
       if (!$notification.length || !$notification.hasClass('unread')) return
       e.preventDefault()
       const notificationId = $notification.attr('id').replaceAll('js-notification-id-', '')
       const notificationLink = $notification.find('.notification-item__inner')
       const linkHref = notificationLink ? notificationLink.attr('href') : ''

       $.ajax({
         type: 'POST',
         url: `/notifications/read/${notificationId}`,
         success() {
           window.Turbolinks.visit(linkHref)
           return false
         },
         error() {
           window.Turbolinks.visit(linkHref)
           return false
         },
       })
     }

     initToolbar()
     initNotificationLink()
   }

  notificationContent.addEventListener('click', onNotificationClick)
}

export default initNotification
