import '../stylesheets/application.scss'
import '../javascripts/application'
import '../controllers'
import './_vue'


try {
  const images = require.context('../images', true, /\.(jpg|jpeg|png|svg|ico|cur|woff)$/)
  const imagePath = name => images(name, true)
} catch (e) {
  console.log(e)
}
