function rateRatingInit() {
  const rateRatingAll = Array.from(document.querySelectorAll('.rate-rating'));
  const CLASS_RATE_RATING = 'rate-rating';
  const CLASS_RATE_RATING_ACTIVE = 'rate-rating_active';
  const CLASS_ICONS = 'rate-icons';
  const CLASS_ICON_CLEW = 'icon-clew';
  const CLASS_ICON_CLEW_DISABLED = 'rate-icons__icon_disabled';
  const CLASS_RATING_TEXT = 'rate-rating__text';
  if (!rateRatingAll.length) return;

  rateRatingAll.forEach((rateRating) => {
    const iconClewAll = Array.from(rateRating.querySelectorAll(`.${CLASS_ICON_CLEW}`));
    iconClewAll.forEach((icon) => {
      icon.addEventListener('mouseenter', onIconMouseEnter);
      icon.addEventListener('click', onIconClick);
      rateRating.querySelector(`.${CLASS_ICONS}`).addEventListener('mouseleave', onIconMouseLeave)
    });
    function onIconMouseEnter(e) {
      const rateRating = e.target.closest(`.${CLASS_RATE_RATING}`);
      const score = e.target.dataset.score;
      changeRating(score, rateRating)
    }

    function onIconClick(e) {
      const rateRating = e.target.closest(`.${CLASS_RATE_RATING}`);
      const score = e.target.dataset.score;
      changeRating(score, rateRating, true)
    }

    function changeRating(score, rateRating, force) {
      const iconClew = Array.from(rateRating.querySelectorAll(`.${CLASS_ICON_CLEW}`));
      const iconClewFilter = iconClew.filter((icon) => +icon.dataset.score <= +score);
      const ratingText = rateRating.querySelector(`.${CLASS_RATING_TEXT}`);
      ratingText.textContent = score ? `${this.$t('select.rating.title')} ${score}+`
        : `${this.$t('select.rating.title')}: ${this.$t('select.rating.all')}`
      if (force) {
        rateRating.dataset.rate = score;
        if (!rateRating.classList.contains(CLASS_RATE_RATING_ACTIVE)) {
          rateRating.classList.add(CLASS_RATE_RATING_ACTIVE);
        }
      }
      iconClew.forEach((icon) => {
        if (icon.classList.contains(CLASS_ICON_CLEW_DISABLED)) return;
        icon.classList.add(CLASS_ICON_CLEW_DISABLED);
      });
      iconClewFilter.forEach((icon) => {
        icon.classList.remove(CLASS_ICON_CLEW_DISABLED)
      })
    }
    function onIconMouseLeave(e) {
      const rateRating = e.target.closest(`.${CLASS_RATE_RATING}`);
      const rate = rateRating.dataset.rate;
      changeRating(rate, rateRating)
    }
  })
}

export default rateRatingInit;