function initPopup() {
  const buttonAll = Array.from(document.querySelectorAll('.js-popup-btn'))
  const CLASS_POPUP = 'js-popup'
  const LINK_RESET_ACTIVE = 'log-in__link-reset_active'

  function initEmail() {
    const inputReset = $('.input-reset input[type="email"]')
    const email = $('#user_email')
    if (!email.length || !email.val() || inputReset.val()) return
    inputReset.val(email.val())
  }

  function onBtnClick(e) {
    const $btn = $(e.target)
    const popup = $btn.find(`.${CLASS_POPUP}`)
    popup.toggle()
    $btn.toggleClass(LINK_RESET_ACTIVE)
    if (popup.css('display') !== 'none') {
      initEmail()
    }
  }

  buttonAll.forEach((btn) => {
    btn.addEventListener('click', onBtnClick)
  })
}
export default initPopup
