import initPolyfill from './polyfill'
import FlashNotice from './flash_notice'
import saveFavourites from './save-favourites'
import dropFilesInit from './drop-files'
import rateRatingInit from './rate-rating'
import initPopup from './popup'
import inputCheckbox from './checkbox'
import validationFormInit from './validationFormInit'
import initNavScroll from './nav-scroll'
import initList from './list'
import magnificPopupInit from './magnificPopupInit'
import initToggleFilters from './toggle-filters'
import initFormFilters from './form-filters'
import { initChat, initTextarea } from './chat'
import initDropdown from './dropdown'
import initSearch from './search'
import initSort from './sort'
import initLoader from './loader'
import initComment from './comment'
import initToggle from './toggle'
import initCopy from './copy'
import initCheckboxToggle from './checkbox-toggle'
import initCloneHtml from './clone-html'
import initDelForm from './delete-form'
import initNotification from './notification'
import initTooltip from './tooltip'
import initHeaderScroll from './header-scroll'

document.addEventListener('turbolinks:load', () => {
  initPolyfill()
  new FlashNotice()
  saveFavourites()
  dropFilesInit()
  rateRatingInit()
  initPopup()
  inputCheckbox()
  validationFormInit()
  initNavScroll()
  initList()
  magnificPopupInit()
  initToggleFilters()
  initFormFilters()
  initChat()
  initDropdown()
  initSearch()
  initSort()
  initLoader()
  initComment()
  initToggle()
  initCopy()
  initCheckboxToggle()
  initCloneHtml()
  initDelForm()
  initNotification()
  initTooltip()
  initHeaderScroll()
  initTextarea()
})
