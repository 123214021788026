import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/tooltip'
import './site/font'

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'

import './site/init'


import { ready } from './push/push'
import { ready as companion } from './push/companion'

const jQuery = require('jquery')
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery

Turbolinks.start()
Rails.start()

require('channels')

document.addEventListener('turbolinks:load', companion)
document.addEventListener('turbolinks:load', ready)
