const PUBLIC_KEY = window.vapidPublicKey

function ready() {
  if (PUBLIC_KEY) {
    setup()
  }
}

function setup() {
  if (!window.PushManager) {
  }

  if (!ServiceWorkerRegistration.prototype.showNotification) {
    return
  }

  if (Notification.permission !== 'granted') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        subscribe()
      }
    })
  } else {
    // subscribe()
  }
}

function subscribe() {
  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    const { pushManager } = serviceWorkerRegistration
    pushManager.getSubscription()
      .then((subscription) => {
        if (subscription) {
          refreshSubscription(pushManager, subscription)
        } else {
          pushManagerSubscribe(pushManager)
        }
      })
  })
}

function refreshSubscription(pushManager, subscription) {
  return subscription.unsubscribe().then((bool) => {
    pushManagerSubscribe(pushManager)
  })
}

function pushManagerSubscribe(pushManager) {
  pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: PUBLIC_KEY,
  })
    .then()
    .then(() => {
      sendNotification()
    })
    .catch((e) => {
      if (Notification.permission === 'denied') {
      } else {
      }
    })
}


function getSubscription() {
  return navigator.serviceWorker.ready
    .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription()
        .catch((error) => {
        }))
}

function sendNotification() {
  getSubscription().then(subscription => fetch('/keys', {
      headers: formHeaders(),
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ subscription: subscription.toJSON() }),
    }).then((response) => {
      if (response.status >= 500) {
        alert('Sorry, there was a problem sending the notification. Try resubscribing to push messages and resending.')
      }
    })
      .catch((e) => {
      }))
}

function formHeaders() {
  return new Headers({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': authenticityToken(),
  })
}

function authenticityToken() {
  return document.querySelector('meta[name=csrf-token]').content
}

export { ready }
