function initList() {
  const listAll = Array.from(document.querySelectorAll('.js-list'))
  if (!listAll.length) return

  const onLinkClick = (list, link) => (e) => {
    e.preventDefault()
    const itemAll = Array.from(list.querySelectorAll('.js-list-item'))
    link.style.display = 'none'
    itemAll.forEach((item) => {
      item.style.display = 'block'
    })
  }

  listAll.forEach((list) => {
    const link = list.querySelector('.js-list-link')
    if (!link) return
    link.addEventListener('click', onLinkClick(list, link))
  })
}

export default initList
