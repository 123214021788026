function initCheckboxToggle() {
  const $checkboxToggle = $('.js-checkbox-toggle')
  if (!$checkboxToggle.length) return

  function onCheckboxChange(e) {
    const id = $(e.target).attr('data-toggle-id')
    if (!id) return
    $(`#${id}`).toggle()
  }

  $checkboxToggle.on('change', onCheckboxChange)
}

export default initCheckboxToggle
