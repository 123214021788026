function initFormFilters() {
  const formFilters = document.querySelector('.form-filters')
  if (!formFilters) return

  formFilters.addEventListener('submit', (e) => {
    const formData = new FormData(e.target)
    const search = new URLSearchParams(formData)
    e.preventDefault()
    window.history.pushState(null, null, `${window.location.origin}${window.location.pathname}?${search}`)
  })
}

export default initFormFilters
