import axios from 'axios'

function dropFilesInit() {
  const dropAreaAll = Array.from(document.querySelectorAll('.js-drop-area'))
  const CLASS_DROP_AREA = 'js-drop-area';
  const CLASS_DROP_FILES_DRAG_ENTER = 'drop-files_drag-enter';
  const CLASS_DROP_FILES_ERROR = 'drop-files_error';
  if (!dropAreaAll.length) return;
  // PreventDefault
  ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    dropAreaAll.forEach((dropArea) => {
      dropArea.addEventListener(eventName, preventDefaults, false)
    })
  });
  function preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }
// dragenter && dragover
  ['dragenter', 'dragover'].forEach(eventName => {
    dropAreaAll.forEach((dropArea) => {
      dropArea.addEventListener(eventName, highlight, false)
    })
  })
  ;['dragleave', 'drop'].forEach(eventName => {
    dropAreaAll.forEach((dropArea) => {
      dropArea.addEventListener(eventName, unhighlight, false)
    })
  })
  function highlight(e) {
    const dropArea = e.target.closest(`.${CLASS_DROP_AREA}`);
    dropArea.classList.add(CLASS_DROP_FILES_DRAG_ENTER);
  }
  function unhighlight(e) {
    const dropArea = e.target.closest(`.${CLASS_DROP_AREA}`);
    dropArea.classList.remove(CLASS_DROP_FILES_DRAG_ENTER);
  }

  // drop
  dropAreaAll.forEach((dropArea) => {
    dropArea.addEventListener('drop', handleDrop, false)
    const inputFile = dropArea.querySelector('input[type="file"]');
    inputFile.addEventListener('change', onInputFileChange)
  })
  
  function onInputFileChange(e) {
    const dropArea = e.target.closest(`.${CLASS_DROP_AREA}`);
    handleFiles(e.target.files, dropArea)
  }
  function handleDrop(e) {
    const dropArea = e.target.closest(`.${CLASS_DROP_AREA}`);
    let dt = e.dataTransfer
    let files = dt.files
    handleFiles(files, dropArea)
  }

  function handleFiles(files, dropArea) {
    ([...files]).forEach((file) => uploadFile(file, dropArea))
  }

  function uploadFile(file, dropArea) {
    const output = document.getElementById('output');
    const url = '/upload/server'
    const formData = new FormData()
    const nameAll = Array.from(dropArea.querySelectorAll('.drop-files__name'));
    const sizeAll = Array.from(dropArea.querySelectorAll('.drop-files__size'));
    formData.append('file', file)
    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      }
    };

    nameAll.forEach(name => name.textContent = file.name);
    sizeAll.forEach(size => size.textContent = file.size && `${(file.size / 1000000).toFixed(1)} MB`);

    axios.put(url, formData, config)
      .then(function (res) {
        output.className = 'container';
        output.innerHTML = res.data;
      })
      .catch(function (err) {
        dropArea.classList.add(CLASS_DROP_FILES_ERROR)
        const errorText = dropArea.querySelector('.drop-files__error__text');
        errorText.textContent = err.message;
      });

  }
}

export default dropFilesInit;