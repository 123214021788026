function initSort() {
  const sortCount = $('.js-sort')
  const selectAll = $('.form-filters__selects select')
  const CLASS_JS_SORT = 'js-sort'
  const CLASS_TD = 'offers-catalog__td js-sort'
  const ALL = 'all'
  const ASC = 'asc'
  const DESC = 'desc'
  if (!sortCount) return

  function onSortCountClick(e) {
    const { target } = e
    const elem = $(target).closest(`.${CLASS_JS_SORT}`)
    if (!elem.length) return
    const select = $(`select[name=q_sort_${elem.data('sort')}]`)
    sortCount.removeClass()
    sortCount.addClass(CLASS_TD)
    switch (select.val()) {
      case ALL:
        elem.addClass(ASC)
        selectAll.val('all')
        select.val(ASC)
        break
      case ASC:
        elem.addClass(DESC)
        selectAll.val('all')
        select.val(DESC)
        break
      case DESC:
        selectAll.val('all')
        select.val(ALL)
        break
      default:
    }

    const btnSubmit = $('.form-filters button[type=submit]')
    if (btnSubmit.length) {
      btnSubmit.trigger('click')
    }
  }
  sortCount.on('click', onSortCountClick)
}

export default initSort
