function ready() {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js')
      .then((reg) => {
      })
  } else {

  }
}
export { ready }
