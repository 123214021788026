function initDelForm() {
  const $linkDelForm = $('.js-link-del-form')
  if (!$linkDelForm.length) return

  function onLinkDelFormClick(e) {
    e.preventDefault()
    $(e.target).closest('form').hide()
  }
  $linkDelForm.on('click', onLinkDelFormClick)
}

export default initDelForm
