export default {
  // optionsInterestList: '/prod/tags',
  // optionsTextileList: '/prod/tags?type=textile',
  // optionsCountry: '/prod/tags/country',
  // locationFactory: '/prod/tags/location?type=factory',
  // locationCustomer: '/prod/tags/location?type=customer',
  optionsInterestList: '/tags',
  optionsTextileList: '/tags?type=textile',
  optionsCountry: '/tags/country',
  locationFactory: '/tags/location?type=factory',
  locationCustomer: '/tags/location?type=customer',
  findCity: 'https://dadata.ru/api/v2/suggest/address',
}
