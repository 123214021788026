function initSearch() {
  const hintsWrap = document.querySelector('.search__hints')
  const input = document.querySelector('.search__input')
  if (!hintsWrap) return

  function onHintsWrapClick(e) {
    const { target } = e
    if (target.nodeName !== 'LI') return
    input.value += ` ${target.textContent}`
  }

  hintsWrap.addEventListener('click', onHintsWrapClick)
}

export default initSearch
