function initNavScroll() {
  const navScroll = document.querySelector('.nav-scroll')
  const CLASS_NAV_SCROLL_FIXED = 'nav-scroll__inner_fixed'
  if (!navScroll) return
  const navScrollWrap = document.querySelector('.nav-scroll__wrap')
  const { height } = navScroll.getBoundingClientRect()
  function onWindowScroll() {
    if (navScroll.getBoundingClientRect().top < 96) {
      navScroll.style.minHeight = `${height}px`
      navScroll.classList.add('active')
      navScrollWrap.classList.add(CLASS_NAV_SCROLL_FIXED)
    } else {
      navScroll.style.minHeight = 'auto'
      navScrollWrap.classList.remove(CLASS_NAV_SCROLL_FIXED)
      navScroll.classList.remove('active')
    }
  }

  window.addEventListener('scroll', onWindowScroll)
}

export default initNavScroll
