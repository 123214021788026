const valForm = (anyForm) => {
  const form = anyForm
  const buttonSubmit = form.querySelector('button[type="submit"]')
  const inputSubmit = form.querySelector('input[type="submit"]')
  const inputNameAll = form.querySelectorAll('.inputName')
  const inputSurName = form.querySelector('.inputSurName')
  const inputMidName = form.querySelector('.inputMidName')
  const inputEmail = form.querySelector('input[type="email"]')
  const inputFirstPass = form.querySelector('.inputFirstPass')
  const inputLastPass = form.querySelector('.inputLastPass')
  const inputUserPass = form.querySelector('.inputUserPass')
  const inputTel = form.querySelector('.inputTel')
  const inputText = form.querySelector('.inputText')
  const inputBirthdayAll = form.querySelectorAll('.inputBirthday')
  const inputCheckboxAll = form.querySelectorAll('input[type="checkbox"]')
  const inputAll = Array.from(form.querySelectorAll('input:not([type="hidden"])'))

  // доб-е placeholder
  function checkPlaceholder(target) {
    const parent = target.parentNode
    const { value } = target
    const inputPlaceholder = parent.querySelector('.input-placeholder')
    if (value && !inputPlaceholder) {
      const inputPlaceholderDiv = document.createElement('div')
      inputPlaceholderDiv.classList.add('input-placeholder')
      inputPlaceholderDiv.textContent = target.placeholder
      parent.appendChild(inputPlaceholderDiv)
    }
    if (!value && inputPlaceholder) {
      inputPlaceholder.remove()
    }
  }

  function onInputChange(e) {
    checkPlaceholder(e.target)
  }
  if (inputAll.length) {
    inputAll.forEach(input => input.addEventListener('input', onInputChange))
  }

  const deleteErrorRight = (container) => {
    const error = container.querySelector('.error-message-right')
    if (error) error.remove()
  }

  const deleteErrorTop = () => {
    const error = form.querySelector('.error-message-top')
    if (error) error.remove()
  }

  // Сброс ошибки
  const resetError = (container) => {
    const errorMsg = container.querySelector('.error-message')
    const markerAll = Array.from(container.querySelectorAll('.marker'))
    container.classList.remove('error')
    container.classList.remove('complete')
    if (errorMsg) {
      errorMsg.remove()
    }
    if (markerAll.length) {
      markerAll.forEach(marker => marker.remove())
    }
    deleteErrorRight(container)
    deleteErrorTop()
  }

  // Общая проверка всех инпутов
  const allowSubmit = (allowForm) => {
    const inputArray = [].slice.call(allowForm.querySelectorAll('.input.required'))
    const activateArray = inputArray.filter(inputComplete => inputComplete.classList.contains('complete'))
    if (!buttonSubmit) return
    if (activateArray.length === inputArray.length) {
      buttonSubmit.classList.remove('disabled')
      buttonSubmit.removeAttribute('disabled')
    } else {
      buttonSubmit.classList.add('disabled')
      buttonSubmit.setAttribute('disabled', 'disabled')
    }
  }

  // доб-е маркера
  const addMarker = (container, leftPosition) => {
    if (container.querySelector('.markerCross')) return
    const marker = document.createElement('div')
    marker.classList.add('marker')
    marker.classList.add('markerCross')
    if (leftPosition) {
      marker.classList.add('markerCross_left-position')
    }
    container.appendChild(marker)

    // Событие клика на крестик
    marker.addEventListener('click', (e) => {
      const parent = e.target.parentNode
      const input = parent.getElementsByTagName('input')
      const anchors = [].slice.call(input)
      anchors.forEach((item) => {
        item.value = ''
      })
      resetError(parent)
      allowSubmit(anyForm)
      checkPlaceholder(parent.querySelector('input'))
    })
  }

  const addMarkerShowPassword = (container) => {
    if (container.querySelector('.marker-show-password')) return
    const input = container.querySelector('input')
    const marker = document.createElement('div')
    marker.classList.add('marker')
    marker.classList.add('marker-show-password')
    if (input.type === 'text') {
      marker.classList.add('marker-show-password_active')
    }
    container.appendChild(marker)

    // Событие клика на иконку глаза
    marker.addEventListener('click', (e) => {
      const parent = e.target.parentNode
      const markerShowPassword = parent.querySelector('.marker-show-password')
      const input = parent.querySelector('input')
      if (markerShowPassword.classList.contains('marker-show-password_active')) {
        markerShowPassword.classList.remove('marker-show-password_active')
        input.type = 'password'
      } else {
        markerShowPassword.classList.add('marker-show-password_active')
        input.type = 'text'
      }
    })
  }

  const showErrorTop = (container, errorMessage) => {
    const error = form.querySelector('.error-message-top')
    if (error) {
      error.innerHTML = errorMessage
    } else {
      container.classList.add('error')
      container.classList.remove('complete')
      const msgElem = document.createElement('p')
      const msgTitle = document.createElement('span')
      msgElem.className = 'error-message-top'
      msgTitle.innerHTML = errorMessage
      msgElem.appendChild(msgTitle)
      form.insertAdjacentElement('afterbegin', msgElem)
    }
  }

  // Показ ошибки, создание сообщения
  const showError = (container, errorMessage) => {
    container.classList.add('error')
    container.classList.remove('complete')
    const msgElem = document.createElement('p')
    const msgTitle = document.createElement('span')
    msgElem.className = 'error-message'
    msgTitle.innerHTML = errorMessage
    msgElem.appendChild(msgTitle)
    container.appendChild(msgElem)
  }

  const showErrorRight = (container, errorTitleProps, errorMessage) => {
    if (form.querySelector('.error-message-right')) return
    const error = document.createElement('div')
    error.className = 'error-message-right'
    const errorTitle = document.createElement('p')
    errorTitle.className = 'error-message-right__title'
    errorTitle.innerHTML = errorTitleProps
    const errorText = document.createElement('p')
    errorText.innerHTML = errorMessage
    errorText.className = 'error-message-right__text'
    error.appendChild(errorTitle)
    error.appendChild(errorText)
    container.appendChild(error)
  }
  // Успешное прохождение проверки
  const complete = (container) => {
    container.classList.remove('error')
    container.classList.add('complete')
  }
  // Успешная проверка паролей
  const completePass = () => {
    // resetError(inputFirstPass.parentNode)
    // resetError(inputLastPass.parentNode)
    complete(inputFirstPass.parentNode)
    complete(inputLastPass.parentNode)
  }

  // Проверка пароля
  const validationPass = (input) => {
    const val = input.value
    const parent = input.parentNode
    resetError(parent)

    if (val) {
      addMarker(parent, true)
      addMarkerShowPassword(parent)
    }
    const re = /[!@#$%^&*()_+?/]/
    switch (true) {
      case !val:
        return false
      case val.length < 6:
        showError(parent, 'Введите не менее 6 символов')
        break
      case val.length >= 32:
        showError(parent, 'Введите не более 32 символов')
        break
      case inputFirstPass && inputLastPass && inputLastPass.value.length > 0
      && inputFirstPass.value !== inputLastPass.value:
        showErrorTop(parent, 'Пароли не совпадают')
        break
      // case !re.test(val):
      //   showErrorRight(parent, 'Weak Password', 'Use symbols: !@#$%^&*()_+?/ <br> For example: Answer=42!')
      //   break
      case inputFirstPass && inputLastPass && inputFirstPass.value === inputLastPass.value:
        deleteErrorRight(parent)
        completePass()
        break
      default:
    }
  }

  // Проверка пользовательского пароля
  const userPass = (input) => {
    const val = input.value
    const parent = input.parentNode
    resetError(parent)
    if (val) {
      addMarker(parent)
    }
    if (!val) {
      return false
    }

    if (val.length < 6) {
      showError(parent, 'Введите весь ваш пароль')
    } else if (val.length >= 32) {
      showError(parent, 'Введите не более 32 символов')
    } else {
      complete(parent)
    }
  }

  // Проверка имени
  const validateName = (input) => {
    const val = input.value
    const parent = input.parentNode
    resetError(parent)
    if (val) {
      addMarker(parent)
    }
    if (!val) {
      return false
    }

    if (val.length >= 32) {
      showError(parent, 'Введите ваше сокращенное имя')
    } else if (
      /(?:^|[^a-zA-Zа-яА-ЯёЁ])(?:Суперпользователь|Модератор|Админ|Администратор|Редактор)(?![a-zA-Zа-яА-ЯёЁ])/i.test(
        val,
      ) === true
    ) {
      showError(parent, 'Введите ваше настоящее имя')
    } else if (/([0-9!@#$%^&*"№;:?+=_])/i.test(val) >= 1) {
      showError(parent, 'Только буквы')
    } else if (val.length === 1) {
      showError(parent, 'Введите ваше полное имя')
    } else {
      complete(parent)
      return true
    }
    return false
  }

  // Проверка фамилии
  const validateSurName = (input) => {
    const val = input.value
    const parent = input.parentNode

    if (val) {
      addMarker(parent)
    }

    resetError(parent)
    switch (true) {
      case !val: return false
      case !val.length <= 2:
        showError(parent, 'Введите вашу полную фамилию')
        return false
      case val.length >= 32:
        showError(parent, 'Введите вашу сокращенную фамилию')
        return false
      case /([0-9a-zA-Z!@#$%^&*"№;:?+=_])/i.test(val) >= 1:
        showError(parent, 'Только буквы русского алфавита')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка отчества
  const validateMidName = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val: return false
      case val.length <= 2:
        showError(parent, 'Введите ваше полное отчество')
        return false
      case val.length >= 32:
        showError(parent, 'Введите ваше сокращенное отчество')
        return false
      case /([0-9a-zA-Z!@#$%^&*"№;:?+=_])/i.test(val) >= 1:
        showError(parent, 'Только буквы русского алфавита')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка email
  const validateEmail = (input) => {
    const val = input.value
    const parent = input.parentNode
    resetError(parent)
    if (val) {
      addMarker(parent)
    }
    switch (true) {
      case !val: return false
      case /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(val) === false:
        showError(parent, 'Email ведён неверно')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка телефона
  const validateTel = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    if (val) {
      addMarker(parent)
    }

    switch (true) {
      case !val: return false
      case val.replace(/\D+/g, '').length < 11:
        showError(parent, 'Укажите верный номер телефона')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка текста
  const validateText = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    if (val) {
      addMarker(parent)
    }

    switch (true) {
      case !val: return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка даты рождения
  const validateBirthday = (input) => {
    const val = input.value
    const parent = input.parentNode
    const valArr = val.split('.')

    resetError(parent)
    if (val) {
      addMarker(parent)
    }

    switch (true) {
      case !val: return false
      case valArr[0] > 31 || valArr[1] > 12 || valArr[2] < 1900
      || valArr[2] > 2019 || val.replace(/\D+/g, '').length < 8:
        showError(parent, 'Укажите верную дату рождения')
        return false
      // case dateFrom > dateInput || dateTo < dateInput:
      //   showError(parent, 'Выбрана не верная категория для ребёнка')
      //   return false
      default:
        complete(parent)
        return true
    }
  }

  const validateCheckbox = (checkbox) => {
    const parent = checkbox.parentNode
    resetError(parent)
    if (checkbox.checked) {
      complete(parent)
    }
  }

  // Обработчики событий на все инпуты
  function checkInputName(inputName) {
    if (inputName.value) {
      validateName(inputName)
      allowSubmit(anyForm)
    }
    inputName.addEventListener('input', (e) => {
      validateName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputNameAll.length) {
    Array.from(inputNameAll).forEach(input => checkInputName(input))
  }

  if (inputSurName) {
    if (inputSurName.value) {
      validateSurName(inputSurName)
      allowSubmit(anyForm)
    }
    inputSurName.addEventListener('input', (e) => {
      validateSurName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputMidName) {
    if (inputMidName.value) {
      validateMidName(inputMidName)
      allowSubmit(anyForm)
    }
    inputMidName.addEventListener('input', (e) => {
      validateMidName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputEmail) {
    if (inputEmail.value) {
      // validateEmail(inputEmail)
      allowSubmit(anyForm)
    }
    const type = inputEmail.dataset.onInput ? 'input' : 'blur'
    inputEmail.addEventListener(type, (e) => {
      if (e.target.value && e.target.value.length < 7 && type === 'input') return
      validateEmail(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputFirstPass) {
    if (inputFirstPass.value) {
      // validationPass(inputFirstPass)
      allowSubmit(anyForm)
    }
    inputFirstPass.addEventListener('input', (e) => {
      validationPass(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputLastPass) {
    if (inputLastPass.value) {
      validationPass(inputLastPass)
      allowSubmit(anyForm)
    }
    inputLastPass.addEventListener('input', (e) => {
      validationPass(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputUserPass) {
    if (inputUserPass.value) {
      userPass(inputUserPass)
      allowSubmit(anyForm)
    }
    inputUserPass.addEventListener('input', (e) => {
      userPass(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputTel) {
    if (inputTel.value) {
      validateTel(inputTel)
      allowSubmit(anyForm)
    }
    inputTel.addEventListener('blur', (e) => {
      validateTel(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputText) {
    if (inputText.value) {
      validateText(inputText)
      allowSubmit(anyForm)
    }
    inputText.addEventListener('blur', (e) => {
      validateText(e.target)
      allowSubmit(anyForm)
    })
  }

  function checkInputBirthday(inputBirthday) {
    if (inputBirthday.value) {
      validateBirthday(inputBirthday)
      allowSubmit(anyForm)
    }
    inputBirthday.addEventListener('blur', (e) => {
      validateBirthday(e.target)
      allowSubmit(anyForm)
    })
  }

  function checkCheckbox(checkbox) {
    if (!checkbox.required) return

    validateCheckbox(checkbox)
    allowSubmit(anyForm)

    $(checkbox).on('change', (e) => {
      validateCheckbox(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputBirthdayAll.length) {
    Array.from(inputBirthdayAll).forEach(input => checkInputBirthday(input))
  }

  if (inputCheckboxAll.length) {
    Array.from(inputCheckboxAll).forEach(checkbox => checkCheckbox(checkbox))
  }
}

const validationFormInit = () => {
  const formAll = Array.from(document.querySelectorAll('.simple_form'))
  if (!formAll.length) return
  formAll.forEach(form => valForm(form))
}

export default validationFormInit
