/* eslint no-console: 0 */

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import Vuex from 'vuex'
import CompWrapper from '../src/CompWrapper.vue'
import HTTP from '../src/api/http-common'
import URL from '../src/api/url'
import i18n from '../src/i18n'

Vue.use(Vuex)
Vue.use(TurbolinksAdapter)
Vue.component('multiselect', Multiselect)
Vue.prototype.$HTTP = HTTP
Vue.prototype.$URL = URL

const store = new Vuex.Store({
  state: {
    filesGallery: [],
    factoryIds: [],
    factories: [],
  },
  getters: {
    filesGallery: state => state.filesGallery,
    factoryIds: state => state.factoryIds,
    factories: state => state.factories,
  },
  mutations: {
    changeFilesGallery(state, payload) {
      state.filesGallery.push(payload)
    },
    changeFactoryIds(state, payload) {
      state.factoryIds = payload
    },
    changeFactories(state, payload) {
      state.factories = payload
    },
    deleteImage(state, payload) {
      state.filesGallery.splice(payload, 1)
    },
    imageMoved(state, payload) {
      const { newIndex, oldIndex } = payload.moved
      const filesGalleryNew = [...state.filesGallery]
      filesGalleryNew[newIndex] = state.filesGallery[oldIndex]
      filesGalleryNew[oldIndex] = state.filesGallery[newIndex]
      state.filesGallery = [...filesGalleryNew]
    },
  },
})

// this.images = this.images.filter(image => image.id !== id)


document.addEventListener('turbolinks:load', () => {
  function mountComponent(globalCompItem) {
    new Vue({
      store,
      i18n,
      components: { CompWrapper },
    }).$mount(globalCompItem)
  }

  const globalComp = Array.from(document.querySelectorAll('.global-comp'))
  if (!globalComp.length) {
    return
  }
  globalComp.forEach(globalCompItem => mountComponent(globalCompItem))
})
