function initToggle() {
  const $btnToggle = $('.js-toggle-btn')
  if (!$btnToggle.length) return

  function toggle(e) {
    e.preventDefault()
    const $btn = $(e.currentTarget)
    try {
      const showId = $btn.attr('data-show-id')
      const hideId = $btn.attr('data-hide-id')
      const $showEl = $(`#${showId}`)
      const $hideEl = $(`#${hideId}`)
      $showEl.toggleClass('hidden')
      if (hideId) {
        $hideEl.toggleClass('hidden')
      }
      if ($showEl.hasClass('hidden')) {
        $btn.removeClass('open')
      } else {
        $btn.addClass('open')
      }
    } catch (er) {
      console.error(er)
    }
  }
  $btnToggle.on('click', toggle)
}

export default initToggle
