import consumer from './consumer'

const PRESENCE_INTERVAL = 60000
document.addEventListener('turbolinks:load', () => {
  const contactHeader = document.querySelector('li.user_notification')
  if (contactHeader) {
    const subscription = consumer.subscriptions.create({
      channel: 'NotificationChannel',
      user: contactHeader.dataset.user,
    }, {
      connected() {
        // console.log('Connected at NotificationChannel')
      },

      updatePresence() {
        this.perform('update_presence')
      },

      disconnected() {
        this.consumer.disconnect()
      },

      received(data) {
        const items = document.getElementById('notification-block-content')
        items.innerHTML = data.notification
        const menu = document.getElementById('js-notifications-menu')
        menu.innerHTML = data.count
      },
    })
    setInterval(() => {
      subscription.updatePresence()
    }, PRESENCE_INTERVAL)
  }
})
