function initToggleFilters() {
  const btn = document.querySelector('.js-toggle-filters-btn');
  if (!btn) return

  const filters = document.querySelector('.js-filters')
  const toggle = document.querySelector('.js-toggle-filters')

  btn.addEventListener('click', () => {
    filters.style.display = 'block'
    toggle.style.display = 'none'
  })
}

export default initToggleFilters
