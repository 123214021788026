WebFontConfig = {
  google: {
    families: ['Open+Sans:400,700:cyrillic,cyrillic-ext&display=swap', 'Source+Sans+Pro:400,700:cyrillic,cyrillic-ext&display=swap', 'Prata:cyrillic,cyrillic-ext&display=swap'],
  },
};
(function (d) {
  const wf = d.createElement('script')
  const s = d.scripts[0]
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'
  wf.async = true
  s.parentNode.insertBefore(wf, s)
}(document))
