function saveFavourites() {
  const saveFavouritesInput = document.querySelector('.js-save-favourites__input');
  const saveFavourites = document.querySelector('.js-save-favourites');
  const btnCreate = document.querySelector('.js-save-favourites__create-btn');
  if (!saveFavouritesInput || !saveFavourites) return;

  const ul = saveFavourites.querySelector('ul')
  const list = saveFavourites.querySelectorAll('li')
  if (!list) return;
  const listText = Array.from(list).map((li) => li.textContent)

  function onSaveFavouritesInputChange(e) {
    const text = e.target.value.trim();
    const filterText = (text) ? listText.filter((listTextItem) => listTextItem.toLowerCase().indexOf(text.toLowerCase()) !== -1) : listText;
    ul.innerHTML = '';

    if (filterText.length) {
      btnCreate.style.display = 'none';
      const filterList = filterText.map((text) => {
        const elem = document.createElement('li');
        elem.textContent = text;
        return elem
      });
      filterList.forEach((list) => {
        ul.appendChild(list)
      })
    } else {
      btnCreate.style.display = 'block';
    }
  }
  saveFavouritesInput.addEventListener('input', onSaveFavouritesInputChange)
}

export default saveFavourites