import Rails from '@rails/ujs'
import ApplicationController from './application_controller'
import { POST } from '../utils/api'

export default class extends ApplicationController {
  static targets = ['list', 'input']

  connect() {
    super.connect()
    this.scroll(100)
  }

  post(event) {
    Rails.stopEverything(event)
    this.stimulate(
      'ChatReflex#post',
      this.element.dataset.chat,
      this.inputTarget.value,
      this.element.dataset.id,
    )
    this.markMessagesRead(this.element.dataset.id, this.inputTarget)
  }

  pressEnter(event) {
    if (event.keyCode === 13 && !event.ctrlKey) {
      Rails.stopEverything(event)
      this.stimulate(
        'ChatReflex#post',
        this.element.dataset.chat,
        this.inputTarget.value,
        this.element.dataset.id,
      )
      this.markMessagesRead(this.element.dataset.id, this.inputTarget)
    }
  }

  scroll(delay = 10) {
    const lists = document.querySelectorAll('[data-chat-target="list"]')
    setTimeout(() => {
      lists.forEach(e => (e.scrollTop = e.scrollHeight))
    }, delay)
  }

  markMessagesRead(id, inputTarget) {
      POST(`/chats/${id}/update_last_seen`).then(() => {
        inputTarget.dispatchEvent(new CustomEvent('sendChatMessage'))
      })
  }
}
