import { Controller } from 'stimulus'
import { POST } from '../utils/api'


export default class extends Controller {
  connect() {
    this.markMessagesRead()
  }


  markMessagesRead() {
    const id = this.element.dataset.chat
    setTimeout(() => {
      POST(`/chats/${id}/update_last_seen`)
        .then((data) => {
        })
    }, 2000)
  }
}
