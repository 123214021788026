<template>
  <component
    :is="data.component"
    v-bind="data.props"
  />
</template>

<script>
    const RatingHistory = () => import('./components/RatingHistory.vue')
    const InputTemplate = () => import('./components/InputTemplate.vue')
    const FactoryCreate = () => import('./components/factory/FactoryCreate.vue')
    const MainInfoEdit = () => import('./components/factory-edit/MainInfoEdit.vue')
    const AboutCompanyEdit = () => import('./components/factory-edit/AboutCompanyEdit.vue')
    const ServiceEdit = () => import('./components/factory-edit/ServiceEdit.vue')
    const DetailsEdit = () => import('./components/factory-edit/DetailsEdit.vue')
    const UploadImage = () => import('./components/factory-edit/UploadImage.vue')
    const Breadcrumb = () => import('./components/factory/Breadcrumb.vue')
    const Filters = () => import('./components/factories-catalog/Filters.vue')
    const FiltersOffers = () => import('./components/filters/FiltersOffers.vue')
    const OfferForm = () => import('./components/offer/OfferForm.vue')
    const ChooseFactory = () => import('./components/factory/ChooseFactory.vue')
    const CustomerCreate = () => import('./components/customer/CustomerCreate.vue')
    const InputInterestListEdit = () => import('./components/customer/InputInterestListEdit.vue')
    const ProfileEdit = () => import('./components/profile/ProfileEdit.vue')
    const EmployersAdd = () => import('./components/employers/EmployersAdd.vue')
    const EmployersAll = () => import('./components/employers/EmployersAll.vue')
    const MaterialsCustomer = () => import('./components/sample/customer/MaterialsCustomer.vue')
    const MaterialsFactory = () => import('./components/sample/factory/MaterialsFactory.vue')
    const Additionally = () => import('./components/sample/Additionally.vue')
    const Agreement = () => import('./components/sample/Agreement.vue')
    const CommentCustomer = () => import('./components/sample/customer/CommentCustomer.vue')
    const LoadFilesWithComment = () => import('./components/sample/customer/LoadFilesWithComment.vue')
    const MoreLabel = () => import('./components/sample/additionally/MoreLabel.vue')
    const LoadFiles = () => import('./components/sample/LoadFiles.vue')
    const DropFilesWithPreview = () => import('./components/drop/DropFilesWithPreview.vue')
    const DropFilesWithInput = () => import('./components/drop/DropFilesWithInput.vue')
    const FilterDeals = () => import('./components/filters/FiltersDeals.vue')

    export default {
        name: 'CompWrapper',
        components: {
            RatingHistory,
            InputTemplate,
            FactoryCreate,
            MainInfoEdit,
            AboutCompanyEdit,
            DetailsEdit,
            ServiceEdit,
            UploadImage,
            Breadcrumb,
            Filters,
            FiltersOffers,
            OfferForm,
            ChooseFactory,
            CustomerCreate,
            InputInterestListEdit,
            ProfileEdit,
            EmployersAdd,
            EmployersAll,
          MaterialsCustomer,
          MaterialsFactory,
          Additionally,
          Agreement,
          CommentCustomer,
          LoadFilesWithComment,
          MoreLabel,
          LoadFiles,
          DropFilesWithPreview,
          DropFilesWithInput,
          FilterDeals,
        },
        data() {
            return {
                data: {},
            }
        },
        created() {
            this.data = JSON.parse(this.$attrs.data)
        },
    }
</script>
