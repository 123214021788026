function initCopy() {
  const $btnCopy = document.querySelector('.js-btn-copy')
  if (!$btnCopy) return
  const $body = document.getElementsByTagName('body')[0]
  const secretInfo = document.querySelector('.js-secret-info').innerHTML

  const copyToClipboard = () => {
    const $tempInput = document.createElement('INPUT')
    $body.appendChild($tempInput)
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select()
    document.execCommand('copy')
    $body.removeChild($tempInput)
  }

  $btnCopy.addEventListener('click', () => {
    copyToClipboard()
  })
}

export default initCopy
