function initComment() {
  const $btnComment = $('.js-btn-comment')
  const $commentForm = $('.js-comment-form')
  if (!$btnComment.length) return

  function toggleForm(e) {
    e.preventDefault()
    try {
      $commentForm.toggleClass('hidden')
      $btnComment.toggleClass('hidden')
    } catch (e) {
      console.error(e)
    }
  }
  $btnComment.on('click', toggleForm)
}

export default initComment
