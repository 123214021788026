function inputCheckbox() {
  const checkboxAll = Array.from(document.querySelectorAll('.checkbox + label'))

  if (!checkboxAll.length) return

  function onLabelKeypress(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      const input = this.previousSibling
      if (!input || input.tagName.toLowerCase() !== 'input') return
      const $input = $(input)
      $input.prop('checked', !$input.prop('checked')).trigger('change')
    }
  }

  function onCheckboxFocus() {
    this.addEventListener('keypress', onLabelKeypress)
  }

  function onCheckboxBlur() {
    this.removeEventListener('keypress', onLabelKeypress)
  }

  checkboxAll.forEach((checkbox) => {
    checkbox.addEventListener('focus', onCheckboxFocus)
    checkbox.addEventListener('blur', onCheckboxBlur)
  })
}

export default inputCheckbox
