function initCloneHtml() {
  const $btnCloneHtml = $('.js-btn-clone-html')
  if (!$btnCloneHtml.length) return

  function onBtnCloneHtmlClick(e) {
    e.preventDefault()
    const id = $(e.target).attr('data-clone-id')
    if (!id) return
    const $elById = $(`#${id}`)
    $elById.children().eq(0).insertBefore($elById)
    if (!$elById.children().length) {
      $btnCloneHtml.hide()
    }
  }

  $btnCloneHtml.on('click', onBtnCloneHtmlClick)
}

export default initCloneHtml
